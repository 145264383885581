.table-container {
  border-radius: 15px;
  margin: 40px 0px;
  padding: 15px 40px 35px 40px;
  border: solid 1px #d9d9d9;
}

@media (max-width: 900px) {
  .table-container {
    margin: 20px 0px;
    padding: 15px 10px 15px 10px;
  }
}
