.text-pill-component {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: center;
  margin: 0;
  color: #404040;
}

