body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.w-complete {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-max-content {
  width: max-content;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pointer {
  cursor: pointer;
}

.none-pointer-events {
  pointer-events: none;
}

/* --------------------- FLEX --------------------- */
.VContainer {
  display: flex;
  flex-direction: column;
}

.IHContainer {
  display: flex !important;
  flex-direction: row !important;
}

.HContainer {
  display: flex;
  flex-direction: row;
}

.align-center {
  display: flex;
  align-items: center !important;
}

.align-end {
  display: flex;
  align-items: flex-end !important;
}

.j-end {
  display: flex;
  justify-content: flex-end;
}

.j-center {
  display: flex;
  justify-content: center !important;
}

.w-max-content {
  width: max-content !important;
}

.j-space-between {
  display: flex;
  justify-content: space-between;
}

.no-underline {
  text-decoration: none;
}

.pill-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding-left: 7px;
  padding-right: 7px;
}

/* --------------------- MARGINS / PADDINGS --------------------- */
.m-0 {
  margin: 0 !important;
}

.mv-20 {
  margin: 0 20px 0 20px;
}

.margin20-10 {
  margin: 20px 10px !important;
}

.pv-10 {
  margin: 0 10px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-5 {
  margin-left: 5px !important;
}
.ml-0 {
  margin-left: 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-9 {
  margin-left: 9px !important; }
  
.ml-20 {
  margin-left: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.minus-mt-5 {
  margin: -5px 0 0 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.m-20 {
  margin: 20px !important;
}

.bm-30 {
  margin-bottom: 30px;
}

.bm-50 {
  margin-bottom: 50px;
}

.bm-80 {
  margin-bottom: 80px;
}

.mv-25 {
  margin: 25px 0 !important;
}

.w-180 {
  width: 180px;
}

.white-space {
  margin-top: 35px;
}

.white-space-l {
  margin-top: 65px;
}

.br-white-space {
  margin-top: 15px;
}

/* --------------------- TEXT --------------------- */
.page-title {
  color: #737373;
  font-weight: 900;
  font-size: 20px;
}

.mayus {
  text-transform: uppercase;
}

.error-message {
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  margin: 5px 0 0 10px;
  color: #a94442;
}

.min-w-700 {
  min-width: 700px;
}

.w-50-percent {
  width: 50%;
}

.w-500 {
  width: 500px;
}

.w-300 {
  width: 300px;
}

.w-760 {
  width: 600px;
}

.w-675 {
  max-width: 675px;
}

.w-75 {
  width: 75px;
}

/* ------------------------------------------------------------------------------------- */

.arrow {
  pointer-events: none;
}

.arrow__path {
  stroke: #339;
  color: #339;
  stroke-width: 3px;
  fill: transparent;
}

.arrow__head {
  stroke: #339;
  stroke-width: 2.5px;
}

.arrow__head line {
  stroke: #339;
  stroke-width: 2.5px;
  color: #339;
  stroke-width: 5px;
}

.btn-icon-table {
  width: 18px;
}

.btn-icon-mini {
  width: 10px;
}

.btn-icon-20 {
  width: 20px !important;
}

.pv-device-form {
  padding: 10px 0 30px 0;
}
/* --------------------- COLOR --------------------- */

.white-text {
  color: white;
}

.red-text {
  color: red;
}

/* --------------------- PRINT --------------------- */

.info-container.approver-with-margin {
  margin-top: -332px;
}

@media print {
  .page-break {
    page-break-after: always;
  }

  .no-print {
    display: none;
  }

  @page {
    size: A4;
    margin: 20mm;
    margin-top: 20mm;
  }

  body {
    font-size: 12px;
    line-height: 1.6;
    color: #000;
    margin: 0;
    padding: 0;
  }

  .printable-content {
    margin: 0 auto;
    width: 90%;
  }

  .info-container.approver-with-margin {
    margin-top: 0 !important;
  }
}
