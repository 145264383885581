.totalRiskContainer {
  border-radius: 15px;
  background: #6a6ad8;
  width: 400px;
}

.creationButtonFilterContainer {
  width: 460px;
  margin-left: 10px;
}

.totalmonthrisk-number {
  color: #fff;
  font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin: 3px 0;
}

.totalmonthrisk-label {
  color: #fff;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 13px;
  text-align: center;
}

.info-form-container {
  width: 100%;
  margin: 15px 0 0 0;
}

@media (max-width: 590px) {
  .totalRiskContainer {
    border-radius: 15px;
    background: #6a6ad8;
    height: 115px;
    width: auto;
  }

  .creationButtonFilterContainer {
    width: auto !important;
    margin: 0 !important;
  }
}

@media (max-width: 900px) {
  .creationButtonFilterContainer {
    margin-left: 10px;
    width: 300px;
  }

  .totalmonthrisk-label{
    font-size: 16px;
  }

  .totalRiskContainer {
    height: auto;
    align-content: center;
    justify-content: center;
  }

  .totalmonthrisk-label {
    font-size: 15px;
  }
  
}
