.result_container {
  background-color: #6a6ad826;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 0 20px;
}

.result_text {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #333333;
}