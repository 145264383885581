.table-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-titles-column {
  color: #6a6ad8;
  font-weight: 700;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  margin-bottom: 20px;
}

.table-default-column {
  color: #737373;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  margin: 0;
}

/* .table-column-container {
  padding: 0 0 0 16px;
} */

.pill-table-column {
  background-color: #fed7aa;
  text-align: center;
  padding: 2px 1px;
  border-radius: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pill-table-column-text {
  font-weight: 700;
  margin: 0;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: white !important;
}

.pill-indicator {
  text-align: center;
  width: max-content;
  padding: 0px 25px;
  border-radius: 7px;
  margin: 0 0 0 10px;
}

.pill-yellow {
  background-color: #f2ea77;
}

.pillText {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: center;
  color: #404040;
}

.column-link {
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-align: left;
  color: #6a6ad8;
  text-decoration: underline;
}

.updown-text-red {
  color: #f43f5e;
}

.updown-text-green {
  color: #22c55e;
}

.custom-empty-message {
  color: #737373;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  margin: 0;
  padding: 0 0 0 16px;
}

.level-redPill {
  background-color: #fecdd3;
  color: #f43f5e;
}

.level-yellowPill {
  background-color: #ffd3b3;
  color: #f97316;
}

.level-greenPill {
  background-color: #c8f0d7;
  color: #22c55e;
}

.level-pill-font {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 70px;
}

.url-column {
  color: #0077e6;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
