.LabelForm {
  color: black;
  font-size: 12px;
  font-weight: bold;
  margin: 6px 0 6px 10px;
}

.bannerText-title-container {
  height: 60px;
}

.dataContainer {
  width: 640px;
  max-width: 100%;
}

@media (min-width: 1200px) {
  .dataContainer {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .dataContainer {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .dataContainer {
    width: 100%;
  }
}
