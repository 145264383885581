.createRist-title {
  color: #339;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.create-form-container {
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 40px;
}

.create-label {
  color: #333;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0 12px 10px;
  vertical-align: middle;
}

.btn-add-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

/* '''''''''''''' */

.level {
  min-width: 90px;
  height: 36px;
  border-radius: 5px;
  margin-left: 10px;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  padding: 0 10px;
}

.low-level {
  background-color: #c8f0d7;
  color: #22c55e;
}

.medium-level {
  background-color: #ffd3b3;
  color: #f97316;
}

.high-level {
  background-color: #fecdd3;
  color: #f43f5e;
}

.extreme-level {
  background-color: #ffb4bd;
  color: #d40e30;
}

.default-level {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.pillcontainer {
  margin: 10px 0 10px 0;
}

@media (max-width: 900px) {
  .create-form-container {
    padding: 15px;
  }
}
