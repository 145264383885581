.question-text {
  font-size: 14px;
  font-weight: 900;
  line-height: 16.8px;
  text-align: left;
}

.option-question-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #737373;
}

.page-question-text {
  color: #737373;
  text-align: center;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
}

.survey-target-info {
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  border-radius: 15px;
}

.table-container-competency {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  margin: 40px 0px;
  padding: 5px 20px 5px 20px;
  border: solid 1px #d9d9d9;
}

.btn-infoExclamation {
  width: 30px !important;
  height: 30px !important;
  margin-left: 10px;
}

.competency-label {
  color: #339;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.competency-name {
  color: #e5515f;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 20px;
}

.competency-description {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 560px;
}

@media (max-width: 750px) {
  .survey-target-info {
    flex-direction: column;
  }
  .competency-description {
    width: auto;
  }
  .page-question-text {
    margin-top: 0;
    margin-bottom: 50px;
  }
}
