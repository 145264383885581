.container {
  background-color: white;
  margin: 2.5px;
  padding: 5px;
}

.containerBlue {
  background-color: #e6f6fb;
  margin: 0 2.5px;
  padding: 5px;
  border-radius: 15px;
}

.containerBlue2 {
  background-color: #ebf5f2;
  margin: 0 2.5px;
  padding: 5px;
  border-radius: 15px;
}

.containerBlue3 {
  background-color: #e8f4fe;
  margin: 0 2.5px;
  padding: 5px;
  border-radius: 15px;
}

.containerRed {
  background-color: #fcf8e3;
  margin: 0 6px 0 6px;
  padding: 5px;
  border-radius: 15px;
}

.map-title-container {
  text-align: center;
  color: #808080;
  text-transform: uppercase;
  font-size: smaller;
  font-weight: bold;
  font-size: 11px;
}

.map-title {
  color: #666666;
  text-align: center;
  font-weight: 900;
  font-size: 35px;
  margin-top: 40px;
}

.map-sub-title {
  color: #666666;
  text-align: center;
  font-weight: 900;
  font-size: 22px;
  margin-bottom: 10px;
}

.map-text {
  color: #808080;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  line-height: 16.8px;
  text-align: center;
  margin: 20px 0 2px 0;
}

.map-text-12 {
  color: #808080;
  font-family: Lato;
  font-size: 12px;
  font-weight: 900;
  line-height: 16.8px;
  text-align: center;
  margin: 0;
}

.mentalMap {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 50px;
  /* justify-content: center; */
}

.breadcrums_separator {
  color: #666666;
}

.topMiddleLeft {
  width: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.topMiddleRigth {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
}

.middleContainer {
  width: 615px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 230px;
}

.container-scroll {
  overflow-x: auto;
  white-space: nowrap;
  justify-content: center;
}

@media screen and (max-width: 1420px) {
  .container-scroll {
    justify-content: inherit;
  }
}

.third-center-container {
  width: 615px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.mind-map-down-arrow {
  width: 10px;
}

.down-arrow-line {
  margin: -4px 0 -6px 0;
  display: flex;
  justify-content: space-around;
  z-index: 10;
}

.conect-arrow-d {
  margin: -7px 0 -6px 0 !important;
}

.conect-arrow-d-II {
  margin: -2px 0 0 0 !important;
  width: 7px;
}
.mind-map-downUp-arrow {
  width: 10px;
  margin-bottom: -17px;
  margin-top: -3px;
  z-index: 5;
}

.mind-map-downUp-arrow-group {
  display: flex;
  justify-content: space-around;
  margin: -10px 0 -15px 0;
  width: 100%;
}

.mind-map-downUp-arrow-groupII {
  display: flex;
  justify-content: space-around;
  margin: -5px 0 -10px 0;
}

.percentage-text-mind-map {
  margin: 0;
  font-size: 11px;
}

.eficacia-container {
  box-shadow: 0px 0px 10px 5px #0000001a;
  border-radius: 20px;
  padding: 3px;
  background-color: #fcf8e3;
}

.eficacia-total-percentage {
  display: flex;
  justify-content: center;
  margin: 10px 10px;
}

.arrow-container-r {
  margin: -30px -10px;
  display: flex;
  justify-content: flex-end;
}

.arrow-container-l {
  margin: -30px -10px;
}

.arrow-container-c {
  margin: -5px 0 -50px 0;
  display: flex;
  justify-content: center;
}

.downUp1 {
  margin: 0 30px 0 30px;
}
.downUp2 {
  margin: 0 60px 0 0;
}
.downUp3 {
  margin: 0 60px 0 60px;
}
.downUp4 {
  margin: 0 35px 0 60px;
}

.container-riskAndOpportunityManagement {
  background-color: #d9edf7;
  padding: 10px 0 10px 0;
  width: 1000px;
  margin: 10px 0 0 0;
  border-radius: 15px;
}

.arrow-container-r-conect {
  margin: -30px -10px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 900px) {
  .mentalMap {
    margin: 0;
  }
}
