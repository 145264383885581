.color-indicator-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.color-indicator-item {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin: 2.5px;
}

.dofa-general-container {
  background-color: #d9edf7;
  margin-top: 15px !important;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.strategic-container {
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  margin: 10px !important;
}

.mid-filter-margin {
  margin: 0 10px;
}

@media (max-width: 900px) {
  .mid-filter-margin {
    margin: 15px 0;
  }
}

@media (max-width: 590px) {
  .color-indicator-container {
    display: flex;
    justify-content: center;
    margin: 0 0 15px 0;
  }
}
