.page-content-powerbi {
  width: 100%;
  padding: 0 50px;
  margin: auto;
}

@media (max-width: 599px) {
  .page-content-powerbi {
    width: 100%;
    padding: 0 10px;
    padding: 10px 50px 0 50px;
    margin: auto;
  }
}

@media (max-width: 450px) {
  .page-content-powerbi {
    width: 100%;
    padding: 0 !important;
  }
}
