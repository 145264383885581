.progress-client-bar {
  padding: 10px 5px;
}

.progress-client-bar {
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #0000001a;
  margin: 8px;
}

.progress-label-text {
  color: #737373;
  text-align: center;
  font-size: small;
  margin: 5px;
}

.progress-label-title {
  color: #333399;
  font-weight: 700;
  font-size: 20px;
}

.custom-progress-bar .p-progressbar-value {
  background-color: #22c55e !important;
  height: 35px;
  border-radius: 5px !important;
}

.p-progressbar {
  height: 45px;
  padding: 2.5px;
  border: solid #D7D7D7;
  background-color: white;
}

@media (max-width: 590px) {
  .progress-label-title {
    font-size: 17px;
  } 
}
