.gm-container {
  padding: 50px 50px;
  height: 625px;
}

.gm-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.lastC-text {
  color: #6a6ad8;
  font-weight: 700;
  margin-right: 10px;
}

.gm-header-title-grey {
  color: #737373;
  font-size: 20px;
  font-weight: 900px;
}

.gm-header-title {
  color: #333399;
  font-size: 20px;
  font-weight: 900px;
}

.header-titles {
  display: flex;
  justify-content: space-between;
}

.header-titles-item {
  display: flex;
  flex-direction: row;
}

.gm-title {
  color: #6a6ad8;
  font-weight: 700px;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  margin-bottom: 20px;
  margin-right: 10px;
  font-weight: bold;
}

.li-item {
  color: #404040;
  font-weight: 400px;
  font-size: 16px;
}

.content-text {
  color: #404040;
  font-weight: 400px;
}

.evidence {
  width: max-content;
  padding: 3px 25px;
  border-radius: 5px;
}

.evidence-text {
  font-family: "Lato", sans-serif;
  color: #404040;
  font-weight: 400px;
  font-size: 16px;
  margin: 0;
}

.selected-tab {
  background-color: orange;
}

.selected-tab-text {
  color: red;
}

@media (max-width: 800px) {
  .header-titles {
    flex-direction: column;
  }
  .content-text {
    font-size: 14px;
  }

  .gm-header-title-grey {
    font-size: 14px;
  }

  .gm-header-title {
    font-size: 14px;
  }

  .lastC-text {
    font-size: 12px;
  }

  .li-item {
    font-size: 12px;
  }

  .gm-header {
    align-items: baseline;
  }
}

@media (max-width: 590px) {
  .gm-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .gm-header-title-grey {
    font-size: 15px;
  }
  .gm-header-title {
    font-size: 15px;
  }
  .lastC-text {
    font-size: 12px;
  }

  .li-item {
    font-size: 12px;
  }
}
